import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesService } from 'src/app/services.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
declare var $:any
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  contactForm : FormGroup;
  contactDetails : any;

  constructor(private service:ServicesService) { }

  ngOnInit() {    
    window.scrollTo(0, 0);
    this.initializeForm()
    this.getDetail()
  }

  initializeForm() {
    this.contactForm = new FormGroup({
      'name' : new FormControl("",[Validators.required, Validators.pattern(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)]),
      'email': new FormControl("", [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'subject' : new FormControl("",[Validators.required, Validators.pattern(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)]),
      'description' : new FormControl("",[Validators.required]),
    })
  }

  getDetail() {
    this.service.showSpinner()
    this.service.getApi('static/get-admin-contact',2).subscribe((res : any) => {
      if(res.status == 200){
        this.contactDetails = res.data
      }
      this.service.hideSpinner()
    })
  }

  submit(){
    this.service.showSpinner()
    let inquiryDto = {
      "name": this.contactForm.value.name,
      "email": this.contactForm.value.email,
      "subject": this.contactForm.value.subject,
      "description": this.contactForm.value.description
    }
    this.service.postApi('static/submit-user-inquiry',inquiryDto,2).subscribe((res:any) => {
      this.service.hideSpinner()
        $('#submit_modal-1').modal('show');
    })
  }

  
}
