import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import * as uuid from 'uuid';
import { environment } from 'src/environments/environment.prod';
declare var google: any;
declare var $ : any

@Component({
  selector: 'app-university-list',
  templateUrl: './university-list.component.html',
  styleUrls: ['./university-list.component.css']
})
export class UniversityListComponent implements OnInit {

  lat : any =28.6519
  long : any=77.2315
  universityList : any = []
  selectedPersonId:any
  countryList :any = []

  myControl = new FormControl();
  filteredOptions: any= []
  responseMessage: any;
  popularCountryList: any=[];
  popularUniversityList: any=[];
  filteredCountryOptions: any=[];
  selectedCountry: any=null;
  selectedUniversity: any=null;
  countryName: any;
  universityId: any;

  constructor(private service:ServicesService,private router : Router) { }

  ngOnInit() {  
    // window.scrollTo(0, 0);
    $('#content').animate({ scrollTop: top }, 200);
    this.getCountry();
    this.getUniversityPriorityBased()
    this.getPopularCountry();
    this.getLocation();
    this.getUniversityList();
  }

  getCountry() {
    let url = environment.static.getCountryList + `?page=0&pageSize=999`
    this.service.showSpinner();
    this.service.getApi(url,2).subscribe((res:any)=>{
      this.service.hideSpinner();
      if(res.status == 200) {
        this.filteredCountryOptions = res.data.allData.content;
      }
    })
  }

  onCountrySelection(val) {
    this.selectedCountry = val;
    this.selectedUniversity = null;
    let url = environment.university.getMatchingUniversity + `?country=${this.selectedCountry}`
    this.service.getApi(url,2).subscribe((res:any)=>{
      if(res.status == 200) {
        this.universityList = res.data;
        if(this.universityList.length == 0) {
          this.service.showErrorMessage('No University record found!')
        }
      }else {
        this.universityList = []
        this.service.showErrorMessage(res.message)
      }
    })
  } 
  
  onUniversitySelection(item) {
    this.selectedUniversity = item.universityName
    if(item.country) {
      this.selectedCountry = item.country
    }
    this.universityId = item.universityDetailsId
  }



  callSlickSlider() {
    $('.slider').slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 500,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }

  getPopularCountry() {
    let url = environment.university.getPopularCountry + `?isFavourate=true&page=0&pageSize=999`
    this.service.getApi(url,2).subscribe((res:any)=>{
      if(res.status == 200) {
        this.popularCountryList = res.data.namesearch.content;
        setTimeout(() => {
          this.callSlickSlider();
        }, 500);
      }
    })
  }


  getUniversityPriorityBased() {
    this.popularUniversityList = []
    let url = environment.university.getPriorityBased + `?page=0&pageSize=9999`;
    this.service.getApi(url,2).subscribe((res:any)=>{
      console.log(res)
      let len =  Object.keys(res).length;
      let objValArr = Object.values(res)
      if(len > 0) {
        for (let i = 0; i < len; i ++) {
          this.popularUniversityList.push(objValArr[i]);
        }
        console.log('called--->',this.popularUniversityList)
      }
      // this.popularUniversityList = res;

      if(res.status == 200) {
        // this.popularUniversityList = res
        console.log('called--->',this.popularUniversityList)
      }
    })

  }

  getUniversityList() {
    let url = environment.university.getUniversityList +`?page=0&pageSize=999`
    this.service.showSpinner();
    this.service.getApi(url,2).subscribe((res:any)=>{
      this.service.hideSpinner();
      if(res.status == 200) {
        this.universityList = res.data
      }
    })
  }

  // getIp() {
  //   this.service.getThirdParty('https://jsonip.com').subscribe((res:any)=>{
  //     this.getLocation(res.ip)
  //   })
  // }


  getLocation() {
    
    this.service.getThirdParty(`https://ipinfo.io?token=c3671aac833bab`).subscribe((res:any)=>{
      console.log(res)
      let arr= []
      arr = res.loc.split(',')
      this.countryName = res.city + '('+ res.country+')';
      this.lat = Number(arr[0]);
      this.long = Number(arr[1]);
    })

    // to find nearby location
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.long = position.coords.longitude
      });
    }
  }

  readMore(id){
    this.router.navigateByUrl("about-university/"+ id)
  }

  addToCompare(id) {
      let primaryId ;
      if(localStorage.getItem('compareId')){
        primaryId = localStorage.getItem('compareId')
      }else{
        primaryId =  uuid.v4();
      }
      console.log("primaryId")
      let data = {}
      localStorage.setItem('compareId',primaryId)
        this.service.postApi(`university/add-university-to-compare?primaryKey=${primaryId}&universityId=${id}`,data,2).subscribe((res:any) => {
            console.log("res-->",res)
            if(res.status == 200){
              this.router.navigateByUrl('compare-university')
            }else{
              this.responseMessage = res.message
              $('#responseModal').modal('show')
            }
        })
  }

  navigateTo(obj) {
    localStorage.setItem('countryObj',JSON.stringify(obj))
    this.router.navigate(['/top-universities',{country:obj.name.replace(/\s/g, '')}])
  }

  navigateToAboutUniversity() {
    if(this.selectedCountry && this.selectedUniversity) {
      this.router.navigate(['about-university',this.universityId])
    }
  }

  viewUniversity(data) {
    this.router.navigate(['about-university',data.universityDetailsId])
  }

  // openLink(social) {
  //   if (social == `National`)
  //     window.open(`https://www.anu.edu.au/`)
  //   else if (social == `Queensland`)
  //     window.open(`https://www.uq.edu.au/`)
  //   else if (social == `Melbourne`)
  //     window.open(`https://www.unimelb.edu.au/`)
  //   else if (social == `Columbia`)
  //     window.open(`https://www.ubc.ca/`)
  //   else if (social == `Toronto`)
  //     window.open(`https://www.utoronto.ca/`)
  //   else if (social == `McGill`)
  //     window.open(`https://www.mcgill.ca/`)
  //   else if (social == `national`)
  //     window.open(`http://www.nuigalway.ie/`)
  //   else if (social == `trinity`)
  //     window.open(`https://www.tcd.ie/`)
  //   else if (social == `dublin`)
  //     window.open(`https://www.ucd.ie/`)
  //   else if (social == `Auckland`)
  //     window.open(`https://www.auckland.ac.nz/en.html`)
  //   else if (social == `Otago`)
  //     window.open(`https://www.otago.ac.nz/`)
  //   else if (social == `AucklandUniversity`)
  //     window.open(`https://www.aut.ac.nz/`)
  //   else if (social == `SchoolOfEconomics`)
  //     window.open(`http://www.lse.ac.uk/`)
  //   else if (social == `Oxford`)
  //     window.open(`https://www.ox.ac.uk/`)
  //   else if (social == `CollegeLondon`)
  //     window.open(`https://www.ucl.ac.uk/`)
  //   else if (social == `Cambridge`)
  //     window.open(`https://www.cam.ac.uk/`)
  //   else if (social == `Stanford`)
  //     window.open(`https://www.stanford.edu/`)
  //   else if (social == `MIT`)
  //     window.open(`https://www.mit.edu/`)
  //   else if (social == `Harvard`)
  //     window.open(`https://www.harvard.edu/`)
  // }

}
