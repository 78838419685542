import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import {FormControl} from '@angular/forms';
import {map, startWith, debounceTime} from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
declare var google: any;
declare var $: any;

@Component({
  selector: 'app-seach-courses',
  templateUrl: './seach-courses.component.html',
  styleUrls: ['./seach-courses.component.css']
})
export class SeachCoursesComponent implements OnInit {

  subject : any;
  selectedQualification : any = "";
  countryList: any;
  courseUniversityList:any= [];
  qualificationList: any = []
  countryName :any 
  myControl = new FormControl();
  filteredOptions: any= [];
  popularCountryList: any=[];
  courseList: any=[];
  searchKey:any=null
  filteredCountryOptions: any=[];
  searchCourse$: Subject<any> = new Subject();
  sub: any;
  selectedSubject: string;
  selectedCountry: any;
  searchCourseList: any=[];

  constructor(private service:ServicesService,private router:Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getCountry()
    // this.getPopularCountry();
    this.getPopularCourses();
    this.subscribeSearchSubject();
  }

  // getPopularCountry() {
  //   let url = environment.university.getPopularCountry + `?isFavourate=true&page=0&pageSize=999`
  //   this.service.getApi(url,2).subscribe((res:any)=>{
  //     if(res.status == 200) {
  //       this.popularCountryList = res.data.namesearch.content;
  //       setTimeout(() => {
  //         this.callSlickSlider();
  //       }, 500);
  //     }
  //   })
  // }

  getCountry() {
    let url = environment.static.getCountryList + `?page=0&pageSize=999`
    this.service.showSpinner();
    this.service.getApi(url,2).subscribe((res:any)=>{
      this.service.hideSpinner();
      if(res.status == 200) {
        this.filteredCountryOptions = res.data.allData.content;
      }
    })
 }

 getPopularCourses() {
    let url =  environment.course.getPopularCourse + `?page=0&pagesize=9999`;
    this.service.showSpinner();
    this.service.getApi(url,2).subscribe((res:any)=>{
      this.service.hideSpinner();
      if(res.status == 200) {
        this.courseList = res.data.FavourateCourses.content
      }
    })
 }

  searchResult(evt) {
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  let value = evt.target.value;
  if (charCode == 13 || (charCode == 8 && this.searchKey.length == 0) || charCode == 16) {
      return;
  } else {
      let string = value.replace(/[`!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g,"");
      if(this.searchKey != string.trim()){
          this.searchCourse$.next(string.trim());
      }
      if(string == '' && value !='') {
          this.service.showErrorMessage(value+ ' not allowed')
      }
      evt.target.value = string
  }
  }

//-----TO SEARCH COURSE---------------------------------------------------------------------------------------------
  subscribeSearchSubject() {
  this.filteredOptions = []
  this.sub = this.searchCourse$.pipe(debounceTime(1000)).subscribe((search) => {
    this.selectedSubject = ''
      this.searchKey = search
      if (this.searchKey.length == 0) {
          return; 
      }
      let arr = []
      if (search.includes('#')) {
          arr = search.split('#')
          search = arr.join("")
      }
      if (!search) {
          return;
      } 

      let url = environment.course.getSeoAndCourseGroup + `?search=${search}&page=0&pageSize=10`;
      let api = this.service.getApi(url,2).subscribe((res:any) => {
          api.unsubscribe();
          if (res.status == 200 && res.data) {
            res.data.forEach(element => {
              // if(element.courseGropuName) {
              //   this.filteredOptions.push({
              //     'value' : element.courseGropuName,
              //     'type': 'GROUP',
              //     'code': element.courseGroupCode
              //   })
              // }else 
              if(element.seoKeyName){
                this.filteredOptions.push({
                  'value' : element.seoKeyName,
                  'type': 'SEO',
                  'code': element.seoKeyCode
                })
              }
            });
            if (this.filteredOptions.length != 0) {
              this.searchKey = null
            } 
          }else {
            this.searchKey = null
            this.filteredOptions = [];
            this.service.showErrorMessage(res.message);
          }
      })
  })
  }


  setQualificationType(value) {
    this.qualificationList = (this.filteredCountryOptions.find(x=>x.name == value)).countryTypeQualification.split(',')
    this.selectedCountry = value
  }

  getSelectedQualificationType(value) {
    this.selectedQualification = value
  }

  searchCourse() {
    this.searchCourseList = [];
    
    let url = environment.course.searchCourse +`?page=0&pageSize=999`
    if(this.selectedSubject) {
      let obj = this.filteredOptions.find(x=>x.value.trim() == this.selectedSubject.trim())
      url = url + `&search=${obj.code}`
    }
    if(this.selectedCountry) {
      url = url + `&country=${this.selectedCountry}`
    }
    if(this.selectedQualification) {
      url = url + `&qualification=${this.selectedQualification}`
    }
    this.service.getApi(url,2).subscribe((res:any)=>{
      if(res.status == 200) {
        this.searchCourseList = res.data.list;
        if(this.searchCourseList.length == 0) {
          this.service.showErrorMessage('No course found!')
        }
      }else {
        this.service.showErrorMessage(res.message)
      }
    },(err:any)=>{
      console.log(err)
      this.service.showErrorMessage(err.error.error)
    })
  }

  navigateTo(item) {
    this.router.navigate(['about-courses',item.courseId])
  }
  
  onSubjectSelect(value) {
    console.log(value)
    this.selectedSubject = value
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }
}
 