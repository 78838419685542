import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  
  privacyData:any;

  constructor(private  service: ServicesService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.service.showSpinner()
    this.getPrivacyPolicy()
  }

  getPrivacyPolicy(){
      this.service.getApi('static/get-static-page-data?pageKey=Privacy Policy',2).subscribe((res:any) => {
        this.service.hideSpinner()
          if(res.status == 200) {
            this.privacyData = res.data
          }
      } , (error :any) => {
        this.service.hideSpinner()
      })
  }

}
