import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-compare-university',
  templateUrl: './compare-university.component.html',
  styleUrls: ['./compare-university.component.css']
})
export class CompareUniversityComponent implements OnInit {

  universityList:any = []
  compareUniversity :any = []
  infoObj :any=
  {
    subjectRanking: 'Subject rankings are provided by one the of reputable ranking resources. Please check the university website for further details',
    courseType: 'Course type names may vary based on individual universities. Please check the university website for further details',
    courseDuration: ' Course duration may vary based on individual universities. Please check the university website for further details',
    startDate: 'Course start dates may vary based on individual universities. Please check the university website for further details',
    modeOfStudy: 'Additional modes of study such as part time and distance learning may also be available for this course. Please check the university website for further details',
    tutionFees: 'Fees are for indicative purposes, please check the University website for details',
    scholarship: 'Scholarships are available. Please check the University website for further details',
    assessment: 'Assessment types may vary based on individual universities. Please check the university website for further details',
    workExperience: 'Work Experience requirements may vary based on individual universities. Please check the university website for further details',
    entryRequirements: 'Entry requirements may vary based on individual universities. Please check the university website for further details',
    careers: 'Detailed career destinations may be available on the university website. Please check the university website for further details',
    worldRanking: 'World rankings are provided by one of the reputable ranking resources. Please check the university website for further details',
    countryRanking: 'Country rankings are provided by one the of reputable ranking resources. Please check the university website for further details',
    livingExpenses: 'Living Expenses are for indicative purposes, C refers to Campus accommodation & OC refers to Off Campus Accommodation. Please check the university website for further details',
    accommodation: ' Subject to availability, provided a booking is made before the deadline. Please check the university website for further details',
    researchFacility: 'Research facilities may be available centrally or within a departments. Please check the university website for further details',
    researchRanking: 'Research rankings are provided by one of the reputable ranking resources. Please check the university website for further details',
    employabilityRanking: 'Employability rankings are provided by one of the reputable ranking resources. Please check the university website for further details',
    partTimeJobs: 'Part-time jobs on campus are subject to availability. Please check the university website for further details'
  }
  constructor(private service : ServicesService,private router:Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.universityList = []
    this.getCompareList()
  }

  getCompareList(){
    // let primaryKey = localStorage.getItem('compareId')
    // this.service.getApi(`university/get-university-compare-list?primaryKey=${primaryKey}`,2).subscribe((res:any) => {
    //   this.universityList = []
    //   this.compareUniversity = []
    //   console.log("res -->",res)
    //   if(res.status == 200){
    //       this.compareUniversity = res.data
    //       this.service.hideSpinner()
    //       res.data.forEach(element => {          
    //       this.getUniversityDetails(element.universityId)          
    //     });
    //   }
    //   this.service.hideSpinner()
    // })
    let arr = []
   arr = JSON.parse(localStorage.getItem('compareUniversityId'))
      arr.forEach(element => {
        this.getUniversityDetails(element)
    });
  }

  getUniversityDetails(id){
      this.service.getApi(`university/get-university-details?universityId=${id}`,2).subscribe((res:any) => {
        if(res.status == 200){
          console.log("universityDetails--->>",res.data)
          this.universityList.push(res.data)
          console.log("universityList-->",this.universityList)
        }
      })
  }

  removeFromCompare(id){
    // let data = {}
    // let primaryKey = localStorage.getItem('compareId')
    // this.service.postApi(`university/remove-university-from-compare?primaryKey=${primaryKey}&universityId=${id}`,data,2).subscribe((res:any) => {
    //     console.log("res-->",res)
    //     this.getCompareList()
    // })
    let arr = JSON.parse(localStorage.getItem('compareUniversityId'));
    let index = arr.findIndex(x=>x == id) ;

    if(index != -1) {
      arr.splice(index,1);
      let index2 = this.universityList.findIndex(x=>x.universityDetails.universityDetailsId == id)
      this.universityList.splice(index2,1)
      localStorage.setItem('compareUniversityId',JSON.stringify(arr))
    }
    if(arr.length == 0) {
      this.router.navigateByUrl('university-list')
    }
  }

  readMore(id){
    this.router.navigateByUrl(`about-university/${id}`)
  }
}
