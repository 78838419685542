import { Component } from '@angular/core';
import { ServicesService } from './services.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'univ';

  constructor(private service:ServicesService){
    console.log('working!')
    document.addEventListener('click', function(event:any) {

      console.log(event.target.className)
      console.log(document.getElementById("mySidenav").style.width);
      if(document.getElementById("mySidenav").style.width == "250px" && event.target.className != "menu-bar") {
        document.getElementById("mySidenav").style.width = "0";
      }
    });
    
  }

}