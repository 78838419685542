import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

  termsAndCondtion : any
  constructor( private service : ServicesService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.service.showSpinner()
    this.getData()
  }

  getData() {
    this.service.getApi('static/get-static-page-data?pageKey=Terms And Condition',2).subscribe((res:any) => {
        if(res.status == 200) {
           this.termsAndCondtion = res.data;
        }
        this.service.hideSpinner()
    },(error :any) => {
        this.service.hideSpinner()
    })
  }

}
