import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-how-to-apply',
  templateUrl: './how-to-apply.component.html',
  styleUrls: ['./how-to-apply.component.css']
})
export class HowToApplyComponent implements OnInit {

  countryList: any = []
  stateList: any = []
  represenativelist: any = []
  myCountry = new FormControl();
  myState = new FormControl();
  location: any
  filteredOptions: Observable<string[]>;
  filteredStateOptions: Observable<string[]>;

  constructor(private service: ServicesService) { }

  ngOnInit() {
      window.scrollTo(0, 0);
      this.getCountyState()
  }

  getCountyState() {
    this.service.getCountryStates()
        .subscribe((data) => {
          this.countryList = data
          this.filteredOptions = this.myCountry.valueChanges
              .pipe(
                  startWith(''),
                  map(value => this._filter(value))
              );
          }, (error) => {
        });
  }

  private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();
      return this.countryList.filter(option => option.country.toLowerCase().includes(filterValue));
  }

  getState() {
      var States = []
      this.myState = new FormControl();
      States = this.countryList.filter((res) => res.country === this.myCountry.value)
      this.stateList = States[0].states
      this.filteredStateOptions = this.myState.valueChanges
        .pipe(
            startWith(''),
            map(value => this._filterState(value))
        );
  }

  private _filterState(value: string): string[] {
      const filterValue = value.toLowerCase();
      return this.stateList.filter(option => option.toLowerCase().includes(filterValue));
  }


  stateSelected() {
  }


  search() {
      this.service.showSpinner()
      let url = `university/v1.1/web/api-for-how-to-apply?page=0&pageSize=100`
      if (this.myCountry.value) {
          url = url + `&country=${this.myCountry.value}`
      }
      if (this.myState.value) {
          url = url + `&state=${this.myState.value}`
      }
      if (this.location) {
          url = url + `&location=${this.location}`
      }
      this.service.getApi(url, 2).subscribe((res: any) => {
        this.represenativelist = []
          this.service.hideSpinner()
          if(res.status == 200) {
            this.represenativelist = res.data.reasponseData
          }else {
              this.service.showErrorMessage(res.message);
          }
      })
  }

  resetForm() {
      this.myCountry = new FormControl()
      this.myState = new FormControl()
      this.location = undefined;
      this.getCountyState()
  }

}
