import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { ServicesService } from 'src/app/services.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-top-universities',
  templateUrl: './top-universities.component.html',
  styleUrls: ['./top-universities.component.css']
})
export class TopUniversitiesComponent implements OnInit,OnDestroy {
  selectedCountry: any;
  universityList: any=[];
  countryObj: any={};
  qualificationList: any=[];
  searchCourse$: Subject<any> = new Subject();
  sub: any;
  searchKey:any=null;
  courseItems: any;
  filteredOptions: any =[]
  selectedSubject: any='';
  selectedQualification: any;
  courseList: any=[];

  constructor(private route: ActivatedRoute, private server:ServicesService, private router:Router) {
    if(localStorage.getItem('countryObj'))
      this.countryObj = JSON.parse(localStorage.getItem('countryObj'));
      this.qualificationList = this.countryObj.countryTypeQualification.split(',')
   }

  ngOnInit() {
    this.selectedCountry = this.countryObj.name
    console.log(this.selectedCountry)
    this.getPopularUniversity();
    this.subscribeSearchSubject();
  }

  searchResult(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    let value = evt.target.value;
    if (charCode == 13 || (charCode == 8 && this.searchKey && this.searchKey.length == 0) || charCode == 16) {
        return;
    } else {
        let string = value.replace(/[`!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g,"");
        if(this.searchKey != string.trim()){
            this.searchCourse$.next(string.trim());
        }
        if(string == '' && value !='') {
            this.server.showErrorMessage(value+ ' not allowed')
        }
        evt.target.value = string
    }
}

  //-----TO SEARCH COURSE---------------------------------------------------------------------------------------------
  subscribeSearchSubject() {
    this.filteredOptions = []
    this.sub = this.searchCourse$.pipe(debounceTime(1000)).subscribe((search) => {
      this.selectedSubject = ''
        this.searchKey = search
        if (this.searchKey.length == 0) {
            return;
        }
        let arr = []
        if (search.includes('#')) {
          arr = search.split('#')
          search = arr.join("")
        }
        if (!search) {
          return;
        } 

       this.getSeoAndCourseGroup()
    })
  } 

  getSeoAndCourseGroup() {
    this.filteredOptions = [];
    let url = environment.course.getSeoAndCourseGroup;
    if(this.searchKey) {
      url = url + `?search=${this.searchKey}`
    }
    // if(this.selectedQualification) {
    //   url = url + `&qualification=${this.selectedQualification}`
    // }
    let api = this.server.getApi(url,2).subscribe((res:any) => {
        api.unsubscribe();
        if (res.status == 200 && res.data) {

          res.data.forEach(element => {
            // if(element.courseGropuName) {
            //   this.filteredOptions.push({
            //     'value' : element.courseGropuName,
            //     'type': 'GROUP',
            //     'code': element.courseGroupCode
            //   })
            // }else 
            if(element.seoKeyName){
              this.filteredOptions.push({
                'value' : element.seoKeyName,
                'type': 'SEO',
                'code': element.seoKeyCode
              })
            }
          });
          if (this.filteredOptions.length != 0) {
            this.searchKey = null
          } 
        }else {
          this.searchKey = null
          this.filteredOptions = [];
          this.server.showErrorMessage(res.message);
        }
    })
  }

  //---TO GET POPULAR UNIVERSITY----------------------------------------------------------------------------------
  getPopularUniversity() {
    let url = environment.university.getPopularUniversity +`?country=${this.selectedCountry}&page=0&pageSize=999`
    this.server.getApi(url,2).subscribe((res:any)=>{
      if(res.status == 200) {
        this.universityList = res.data.searchData
      }
    })
  }

  searchByArea_Course() {
    if(!this.selectedSubject) {
      this.server.showErrorMessage('Please provide subject area!')
      return;
    }
    if(!this.selectedQualification) {
      this.server.showErrorMessage('Please select qualification!')
      return;
    }
    this.courseList  = []
    let url = environment.course.searchCourse +`?page=0&pageSize=999`

    if(this.selectedSubject) {
      let obj = this.filteredOptions.find(x=>x.value.trim() == this.selectedSubject.trim())
      console.log(obj)
      url = url + `&search=${obj.code}`
    }
    if(this.selectedCountry) {
      url = url + `&country=${this.selectedCountry}`
    }
    if(this.selectedQualification) {
      url = url + `&qualification=${this.selectedQualification}`
    }
    this.server.getApi(url,2).subscribe((res:any)=>{
      if(res.status == 200) {
        this.courseList = res.data.list
      }else {
        this.server.showErrorMessage(res.message)
      }
    },(err:any)=>{
      console.log(err)
      this.server.showErrorMessage(err.error.error)
    })
  }

  getSelectedQualificationType(value) {
    this.selectedQualification = value
  }

  onSubjectSelect(value) {
    console.log(value)
    this.selectedSubject = value
  }

  viewUniversity(data) {
    this.router.navigate(['about-university',data.universityDetailsId])
  }

  navigateToCourse(item) {
    this.router.navigate(['about-courses',item.courseId])
}


ngOnDestroy() {
  this.sub.unsubscribe()
}

}

