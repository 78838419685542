import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesService } from 'src/app/services.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm : FormGroup;
  constructor(private router : Router,private service :ServicesService) { }

  ngOnInit() {
      window.scrollTo(0, 0);
      this.initializeForm()
  }

  initializeForm() {
      this.loginForm = new FormGroup ({
        'email': new FormControl("", [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
        'password': new FormControl('', [Validators.required]),
        'rememberMe' : new FormControl(false)
      })
  }

  get length() {
    return this.loginForm.value.email.length
  }

  forgetPassword() {
    this.router.navigateByUrl('forget-password');
  }

  signIn() {
    this.service.showSpinner()
    let data = {
      "email" : this.loginForm.value.email,
      "password" : this.loginForm.value.password
    }
    this.service.postApi('auth',data,2).subscribe((res:any) => {
      this.service.hideSpinner()
      if(res.status == 200){
        this.service.showSuccessMessage("helloo")
        localStorage.setItem('token',res.data.token)
      }else{
        this.service.showErrorMessage(res.message)
      }
    },(error : any) => {
      this.service.showErrorMessage(error.error.message)
      this.service.hideSpinner()
    })
  }

}
