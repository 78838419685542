import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ireland-search-course',
  templateUrl: './ireland-search-course.component.html',
  styleUrls: ['./ireland-search-course.component.css']
})
export class IrelandSearchCourseComponent implements OnInit {

  subject :any;
  qualification :any = "";
  universityList :any;
  popularUniversity :boolean = true;
  courseUniversityList:any = [];

  constructor(private service : ServicesService,private router:Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getUniversityList()
  }


  getUniversityList() {
      this.service.getApi(`university/v1.1/web/get-most-popular-university-by-country-using-subscription-type?country=Ireland&page=0&pageSize=10`,2).subscribe((res :any ) => {
      this.universityList = []
      if(res.status == 200){
        this.universityList = res.data.RESULT_LIST.content ;
      }
    })
  }


  search(){
      this.service.showSpinner()
      this.popularUniversity = false
      let url = `course/v1.1/web/search-and-filter-course-details?page=0&country=Ireland`
      if(this.subject){
        url = url + `&courseName=${this.subject}`
      }
      if(this.qualification){
        url = url + `&qualification=${this.qualification}`
      }
      this.service.getApi(url,2).subscribe((res:any) => {
        this.courseUniversityList = []
        this.service.hideSpinner()
        if(res.status == 200){
          this.courseUniversityList = res.data.list
        }
      })
  }

  readMore(id){
    if(id){
      this.router.navigateByUrl("about-university/"+ id)
    }
  }  

  openLink(social) {
    if (social == `national`)
      window.open(`http://www.nuigalway.ie/`)
    else if (social == `trinity`)
      window.open(`https://www.tcd.ie/`)
    else if (social == `dublin`)
      window.open(`https://www.ucd.ie/`)
  }
}
