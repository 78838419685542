import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesService } from 'src/app/services.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
declare var $:any

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  contactForm : FormGroup;
  subscribeForm : FormGroup;
  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  validPhoneNo:Boolean = true
  subscribeValidPhoneNo:Boolean = true;
  responseMessage :any = ""
  feedbackForm: FormGroup;
  submitted:boolean = false
  presentYear:any
  version:any;
  ngOnInit() {
    this.version = environment.version;
    this.initializeForm();
    this.initFeedback()
    this.presentYear = new Date().getFullYear()
    this.activateRoute.queryParams.subscribe((query :any) => {
      console.log("quey--->>",query)
      if(query){
        if(query.modal == 'subscibe'){
          $('#subscribeModal').modal('show')
        }else if (query.modal == 'helpCentre'){
          $('#locationsetmodal').modal('show')
        }
      }
    })
  }

   constructor(private service:ServicesService,private activateRoute:ActivatedRoute){
    // this.service.initSocket();   
  }
  initFeedback() {
    this.feedbackForm = new FormGroup({
      subject : new FormControl('',Validators.required),
      description : new FormControl('',Validators.required),
    })
  }
  

  startChat(){
    this.service.readyState.subscribe(socketSuccess => {
        console.log("socket --->",socketSuccess)
        if(socketSuccess){
            this.subscribeSocket()
        }
    });
  }

  subscribeSocket() {
      if (this.service.wsExchange.readyState == 1) {
          const data = {
            "toEmail" : "xyz@gmail.com",
            "fromEmail" : "abc@gmail.com",
            "message" :  "oh god"
          }
          this.service.wsExchange.send(JSON.stringify(data));
          this.receiveMessage();
      }
  }

  receiveMessage() {
    this.service.wsExchange.addEventListener('message', (event) => {
      console.log("res--->",event)
      const eventdata = event.data ? JSON.parse(event.data) : [];
      if (eventdata.length) {
        
      }
    })}

  initializeForm() {
    this.contactForm = new FormGroup({
      'firstName' : new FormControl("",[Validators.required, Validators.pattern(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)]),
      'lastName' : new FormControl("",[Validators.required, Validators.pattern(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)]),
      'email': new FormControl("", [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'phone' : new FormControl(undefined,[Validators.required]),
      'description' : new FormControl("",[Validators.required]),
    })

    this.subscribeForm = new FormGroup({
      'firstName' : new FormControl("",[Validators.required, Validators.pattern(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)]),
      'lastName' : new FormControl("",[Validators.required, Validators.pattern(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)]),
      'email': new FormControl("", [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'phone' : new FormControl(undefined,[Validators.required]),
      'userType' : new FormControl("",[Validators.required]),
      'description' : new FormControl(""),
    })
  }


  changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
  
  phoneValidOrNot(){
    let key = this.contactForm.controls['phone'].errors
    if(key){
      if(key['validatePhoneNumber']){
        this.validPhoneNo = key['validatePhoneNumber'].valid
      }else{
        this.validPhoneNo = true
      }
    }else{
      this.validPhoneNo = true
    }
  }


  subscribePhoneValidOrNot(){
    console.log("phone-->",this.subscribeForm.controls['phone'].errors)
    let key = this.subscribeForm.controls['phone'].errors
    if(key){
      if(key['validatePhoneNumber']){
        this.subscribeValidPhoneNo = key['validatePhoneNumber'].valid
      }else{
        this.subscribeValidPhoneNo = true
      }
    }else{
      this.subscribeValidPhoneNo = true
    }
  }

  openSocialLink(social) {
    if (social == `youtube`)
      window.open(`https://www.youtube.com/channel/UC-LVJUbssKgvcYfdA-2rUCg`)
    else if (social == `facebook`)
      window.open(`https://www.facebook.com/universitiesglobal`)
    else if (social == `twitter`)
      window.open(`https://twitter.com/UniversitiesG`)
    else if (social == `linkedin`)
      window.open(`https://www.linkedin.com/company/universitiesglobal/`)
    else if (social == `instagram`)
      window.open(`https://www.instagram.com/universitiesglobal/`)
  }

  connect() {
    this.contactForm.reset()
    $('#locationsetmodal').modal('show')
  }

  submit(){
    $('#locationsetmodal').modal('hide')
    $('#load_modal').modal('show')
  }

  openSubscribeModal(){
    $('#subscribeModal').modal('show')
  }
  
  subscribe(){
    console.log("subscribeForm -->",this.subscribeForm.value)
    this.service.showSpinner()
    let data = {
      "firstName": this.subscribeForm.value.firstName,
      "lastName": this.subscribeForm.value.lastName,
      "phoneNo": this.subscribeForm.value.phone.e164Number,
      "email": this.subscribeForm.value.email,
      "roleStatus": "USER",
      "customerStatus": "SUBSCRIBE",
      "password": "University@1234",
      "userType": this.subscribeForm.value.userType,
      "description": this.subscribeForm.value.description,
      "webUrl": window.location.origin + "/dashboard"
    }
    this.service.postApi('account/signup',data,1).subscribe((res : any) => {
      console.log("res-->",res)
      this.service.hideSpinner()
      if(res.status == 200){
        this.subscribeForm.reset();
        this.responseMessage = "Please verify the mail id"
      }else{
        this.responseMessage = res.message
      }
      $('#After_subscribe').modal('show');
    })
  }


  sendFeedback(){
    this.submitted = true;
    if(this.feedbackForm.invalid){
      return false
    }
    let data = {
      "feedbackSubject": this.feedbackForm.value.subject,
      "description": this.feedbackForm.value.description
    }
    // let url = `university/v1.1/web/add-feedback-for-particular-university?universityDetailsId=${this.id}`
    this.service.postApi(`university/v1.1/web/add-feedback-for-particular-university`,data,2).subscribe((res:any) => {
        console.log("res-->",res)
        if(res.status == 200){
          this.responseMessage = "Thank you for contacting us!"
          this.feedbackForm.reset()
          $('#responseModal').modal('show');
        }
    })
  }

}
