import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ServicesService {
    httpOptions: any={observe: 'response'};

   

    baseUrl = "https://try.readme.io/http://ec2-3-22-2-94.us-east-2.compute.amazonaws.com:2001/";
    representativeBaseUrl = 'http://ec2-35-176-66-190.eu-west-2.compute.amazonaws.com:1617/';
    studentBaseUrl = 'http://ec2-35-176-66-190.eu-west-2.compute.amazonaws.com:1617/';
    webSocketUrl = 'ws://182.72.203.244:2007/chat';
    private subject = new Subject<any>();
    wsExchange: WebSocket;
    wsExExchange: WebSocket;
    liqWSObj: WebSocket;
    wsChatExchange: WebSocket;
    wsMsgChatExchange: WebSocket;
    liquidityWS: any;
    liquidityWSTrade: WebSocket;
    liquidityWSDepth: WebSocket;
    liquidityWSPoloneix: WebSocket;
    activatedSocket: any = '';
    socketStatus: any = false;

    readyState = new BehaviorSubject(0);
    readyToUse = this.readyState.asObservable();
    constructor(private http:HttpClient,private spinner: NgxSpinnerService, private toastr: ToastrService) {
        let url = window.location.href
        if(url.includes('webstag')) {
            this.baseUrl = "https://try.readme.io/http://apistag.univ.global:2001/";
            this.representativeBaseUrl = 'http://ec2-18-216-123-240.us-east-2.compute.amazonaws.com:8004/';
            this.studentBaseUrl = 'http://ec2-18-216-123-240.us-east-2.compute.amazonaws.com:8002/';
        }else if(url.includes('localhost')){
            // this.baseUrl = "https://try.readme.io/http://ec2-3-22-2-94.us-east-2.compute.amazonaws.com:2001/";
            // this.baseUrl = "https://try.readme.io/http://apistag.univ.global:2001/";
            this.baseUrl = "https://try.readme.io/http://api.univ.global:2001/";
            this.representativeBaseUrl = 'http://ec2-18-216-123-240.us-east-2.compute.amazonaws.com:8004/';
            this.studentBaseUrl = 'http://ec2-18-216-123-240.us-east-2.compute.amazonaws.com:8002/';
        }else {
            this.baseUrl = "https://try.readme.io/http://api.univ.global:2001/";
            this.representativeBaseUrl = 'http://rep.univ.global/';
            this.studentBaseUrl = 'http://student.univ.global/';
        }
    }


//================ POST API =========================//
    postApi(url, data, Header) {
        let headers;
        if (Header == 1) {
              headers = new HttpHeaders({
                "Content-Type": "application/json",
                "Authorization":localStorage.getItem('token')
              })
        } 
        else {
              headers = new HttpHeaders({
                "Content-Type": "application/json",
              })
        }
        return this.http.post((this.baseUrl+ url), data, headers)  
    }
  
//================ GET API =========================//    
    getApi(url, isHeader) {
        let headers;
        if (isHeader == 1) {
            headers = new HttpHeaders({
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem('token')
            })       
        }
        else {
            headers = new HttpHeaders({
              "Content-Type": "application/json",
            })       
        }
        return this.http.get((this.baseUrl + url), headers)
    }

    getAPI(url) {
        this.httpOptions['headers'] = new HttpHeaders({
            "Authorization": localStorage.getItem('token')
          })       
      return this.http.get((this.baseUrl + url), this.httpOptions)        
           
    }

    getThirdParty(url) {
        return this.http.get(url,{})
    }

//================ PUT API =========================//
    putApi(url,data:any){
        var headers;
        headers = new HttpHeaders({
            "token": localStorage.getItem('token')
        })
        return this.http.put(this.baseUrl + url,data,headers)
    }

//================ DELETE API =========================//
    deleteApi(url) {
        let headers;
        headers = new HttpHeaders({
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token')
        })     
        return this.http.delete(this.baseUrl + url, headers);
    }

//================ SHOW SPINNER =========================//
    showSpinner() {
        this.spinner.show();
    }
    
//================ HIDE SPINNER =========================//
    hideSpinner() {    
        this.spinner.hide();
    }

//================ SHOW Succes TOASTR =========================//
    showSuccessMessage(message){
        this.toastr.success(message)
    }

//================ HIDE Succes TOASTR =========================//
    showErrorMessage(message){
        this.toastr.error(message)
    }

//================ HIDE Succes TOASTR =========================//
    getCountryStates(): Observable<any> {
        return this.http.get("./assets/json/country.json");
    }

    changeReadyState(message: any) {
        console.log("message--->>",message)
        this.readyState.next(message);
    }

      /* Function for socket event **/
      initSocket() {
        this.wsExchange = new WebSocket(this.webSocketUrl);
        const self = this;
        this.wsExchange.addEventListener('open', (event) => {
            self.socketStatus = true;            
            this.changeReadyState(this.wsExchange.readyState);
        });
        this.wsExchange.addEventListener('close', (event) => {
            self.socketStatus = false;
            self.reConnectSocket();
            self.heartBeatFunc();
        });
    }

    heartBeatFunc() {
        if (this.wsExchange.readyState) {
            this.wsExchange.send('heartbeat');
        }
    }

    /** Function for reconnect socket */
    reConnectSocket() {
        this.wsExchange = new WebSocket(this.webSocketUrl);
        const self = this;
        this.wsExchange.addEventListener('open', (event) => {
            self.socketStatus = true;
            this.changeReadyState(this.wsExchange.readyState);
        });
    }
}
