import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $:any

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  forgetForm : FormGroup;

  constructor(private router : Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.forgetForm = new FormGroup({
      email : new FormControl('', [Validators.required,Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
    })
  }

  submit() {
    $('#submit_modal').modal('show')
  }

  navidateTOlogin(){
    $('#submit_modal').modal('hide');
    this.router.navigateByUrl('login')
  }

}
