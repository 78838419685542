import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  aboutUsData: any;

  constructor(private service : ServicesService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.service.showSpinner()
    this.getData()
  }

  getData() {
      this.service.getApi('static/get-static-page-data?pageKey=About Us',2).subscribe((res:any) => {
          if(res.status == 200) {
            this.aboutUsData = res.data
          }
          this.service.hideSpinner()
      },(error :any) => {
        this.service.hideSpinner()
      })
  }

}
