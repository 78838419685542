import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
declare var $ : any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  howItWorksData:any;
  serviceOfferingData: any;
  responseMessage: any =  "";
  UniversityNewsList: any = [];
  countryNewsList: any = [];
  count :any = {
    country : '06',
    university : '02',
    courses : '500',
    representatives : '10',
  }
  isEDGE: boolean = false;
  popularCountryList: any;
  constructor(private service : ServicesService,private activateRoute:ActivatedRoute,private router:Router) { 

  }


  ngOnInit() {
    this.activateRoute.queryParams.subscribe((query :any) => {
      if(query.token){
        this.verifyEmail(query.token)
      }
    });
    window.scrollTo(0, 0);
    this.getPopularCountry()
    this.getStaticData()
    this.getCountryNews()
    this.getUniversityNews()
  }

  getPopularCountry() {
    let url = environment.university.getPopularCountry + `?isFavourate=true&page=0&pageSize=999`
    this.service.getApi(url,2).subscribe((res:any)=>{
      if(res.status == 200) {
        this.popularCountryList = res.data.namesearch.content
        this.popularCountryList.sort((a, b) => Number(a.priority) - Number(b.priority));        //countryImage
        console.log(this.popularCountryList)
      }
    })
  }

  navigateTo(obj) {
    localStorage.setItem('countryObj',JSON.stringify(obj))
    this.router.navigate(['/top-universities',{country:obj.name.replace(/\s/g, '')}])
  }

  verifyEmail(token){
    $('#verifyEmail').modal('show')
      this.service.getApi(`account/verify-user?token=${token}`,2).subscribe((res:any) => {
        console.log("verify mail res--->",res)
        this.responseMessage = res.message
        $('#verifyEmail').modal('show')
      })
  }

  getStaticData() {
    this.service.getApi('static/get-static-page-data?pageKey=How It Works',2).subscribe((res :any) => {
      if(res.status == 200) {
        this.howItWorksData = res.data
        this.howItWorksData.pageData = this.howItWorksData.pageData.slice(0,400) + '....'
      }
    },(error : any) => {

    })
    this.service.getApi('static/get-static-page-data?pageKey=Service Offering',2).subscribe((res :any) => {
      if(res.status == 200){
        this.serviceOfferingData = res.data;
        this.serviceOfferingData.pageData = this.serviceOfferingData.pageData.slice(0,400) +'....'
      }
    },(error : any) => {
      
    })
    // this.service.getApi('university/v1.1/web/count-university-data',2).subscribe((res:any) => {
    //   this.count.university = res.data.data
    // },(error : any) => {
      
    // })    
    // this.service.getApi('university/v1.1/web/count-representative-data',2).subscribe((res:any) => {
    //   this.count.representatives = res.data.data
    // },(error : any) => {
      
    // })
    // this.service.getApi('course/v1.1/web/search-and-filter-course-details',2).subscribe((res:any) => {
    //   this.count.courses = res.data.totalCount
    // })
  }

  getCountryNews(){
    this.service.getApi('static/get-new-list-by-news-type?newsType=COUNTRY&page=0&pageSize=10',2).subscribe((res:any) => {
      if(res.status == 200){
        this.countryNewsList = res.data
      }
    },(error : any) => {
      
    })
  }

  getUniversityNews(){
    this.service.getApi('static/get-new-list-by-news-type?newsType=UNIVERSITY&page=0&pageSize=10',2).subscribe((res:any) => {
      if(res.status == 200){
        this.UniversityNewsList = res.data
      }
    },(error : any) => {
      
    })
  }


  
}
