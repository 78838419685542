import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService } from 'src/app/services.service';
import * as uuid from 'uuid';
declare var $ : any

@Component({
  selector: 'app-about-courses',
  templateUrl: './about-courses.component.html',
  styleUrls: ['./about-courses.component.css']
})
export class AboutCoursesComponent implements OnInit {
  lat :any ;
  long :any ;
  id : any 
  courseDetails:any={};
  universityList: any = [];
  courseList : any = [];
  responseMessage: any;
  courseDescription: any;
  popularCourseTitle: any ='View More Popular Courses'
  infoObj :any=
  {
    subjectRanking: 'Subject rankings are provided by one the of reputable ranking resources. Please check the university website for further details',
    courseType: 'Course type names may vary based on individual universities. Please check the university website for further details',
    courseDuration: ' Course duration may vary based on individual universities. Please check the university website for further details',
    startDate: 'Course start dates may vary based on individual universities. Please check the university website for further details',
    modeOfStudy: 'Additional modes of study such as part time and distance learning may also be available for this course. Please check the university website for further details',
    tutionFees: 'Fees are for indicative purposes, please check the University website for details',
    scholarship: 'Scholarships are available. Please check the University website for further details',
    assessment: 'Assessment types may vary based on individual universities. Please check the university website for further details',
    workExperience: 'Work Experience requirements may vary based on individual universities. Please check the university website for further details',
    entryRequirements: 'Entry requirements may vary based on individual universities. Please check the university website for further details',
    careers: 'Detailed career destinations may be available on the university website. Please check the university website for further details'
  }

  fakeDataObj: any ={}
  monthArr:any=['January','February','March','April','May','June','July','August','September','October','November','December']


  constructor(private activateRoute:ActivatedRoute,private service:ServicesService,private router:Router) {
    this.fakeDataObj['views'] = Math.round(Math.random())
    this.fakeDataObj['followers'] = Math.round(Math.random())
    this.fakeDataObj['applied'] = Math.round(Math.random())
   }

  ngOnInit() {
    this.courseDetails = {}
    window.scrollTo(0, 0);
    this.activateRoute.params.subscribe((res:any) => {
      this.id =  res.id
      this.getCourseDetails()
    })
  }

  getCourseDetails(){
    this.service.showSpinner()
    this.service.getApi(`course/v1.1/web/view-specific-course?id=${this.id}`,2).subscribe((res : any) => {
      console.log("res-->",res)
      this.service.hideSpinner()
      if(res.status == 200){
        this.courseDetails = res.data.course;
        this.courseDetails.lat = Number(this.courseDetails.lat)
        this.courseDetails.lng = Number(this.courseDetails.lng)
        if(this.courseDetails.universityImageUrl) {
          this.courseDetails.courseImageUrl = this.courseDetails.universityImageUrl
        }
        let current_month = new Date().getMonth() + 1
        this.monthArr.forEach((month,index) => {
          if(this.courseDetails.entryMonth.includes(month) && (index+1 > current_month)) {
            this.courseDetails.entryMonth = this.courseDetails.entryMonth.replace(month,(month + ' - ' + (new Date().getFullYear() + 1)))
          }else {
            this.courseDetails.entryMonth = this.courseDetails.entryMonth.replace(month,(month + ' - ' + (new Date().getFullYear())))
          }
        });
        console.log(this.courseDetails.entryMonth)
        this.courseDetails.universityImageUrl = this.courseDetails.universityImageUrl ? this.courseDetails.universityImageUrl : 'assets/images/univ_bg.jpg'
        this.courseDescription = res.data.courseDescription
        this.getUniversities()
        this.getCourses()
      }
    })
  }

  getUniversities(){
    this.service.showSpinner()    
    this.service.getApi(`university/v1.1/web/get-most-popular-university-by-country-using-subscription-type?country=${this.courseDetails.country}&page=0&pageSize=7`,2).subscribe((res :any ) => {
      console.log("res-->",res)
      if(res.status == 200){
        this.universityList = res.data.RESULT_LIST.content ;        
        this.service.hideSpinner()
      }
    })
  }

  getCourses(){
    this.service.getApi(`course/v1.1/web/search-and-filter-course-details?universityName=${this.courseDetails.universityName}`,2).subscribe((res:any) => {
      console.log("res-->>",res)
      if(res.status == 200){
        this.courseList = res.data.list
      }
    })
  }

  addToCompare(id){
    // this.service.showSpinner()
    // let primaryId ;
    // if(localStorage.getItem('compareCourseId')){
    //   primaryId = localStorage.getItem('compareCourseId')
    // }else{
    //   primaryId =  uuid.v4();
    //   localStorage.setItem('compareCourseId',primaryId)
    // }
    // let data = {}
    //   this.service.postApi(`course/add-course-to-compare?courseId=${id}&primaryKey=${primaryId}`,data,2).subscribe((res:any) => {
    //       console.log("res-->",res)
    //       if(res.status == 200){
    //         this.router.navigateByUrl('compare-courses')
    //       }else{
    //         this.service.hideSpinner()
    //         this.responseMessage = res.message
    //         $('#responseModal').modal('show')
    //       }
    //   })
      let arr = []
      if(localStorage.getItem('compareCourseId')){
        arr = JSON.parse(localStorage.getItem('compareCourseId'));
        if(arr.length >=3) {
          alert('You have already added 3 courses in comparison list. Please remove to add more!')
          this.router.navigateByUrl('compare-courses')
          return;
        }
        let index = arr.findIndex(x=>x == id);
        if(index == -1) {
          arr.push(id);
          localStorage.setItem('compareCourseId',JSON.stringify(arr))
          this.router.navigateByUrl('compare-courses')
        }else {
          this.responseMessage = 'Course already added for comparison!'
          $('#responseModal').modal('show')
        }
      }else { 
        arr.push(id);
        localStorage.setItem('compareCourseId',JSON.stringify(arr))
        this.router.navigateByUrl('compare-courses')
      }

  }

  addToFavourite(type){
    if(type == 'REP'){
      let url = this.service.representativeBaseUrl + `favourite-courses?addToFav=${this.id}`
      window.open(url, '_blank');
    }else if(type == 'STU'){
      let url = this.service.studentBaseUrl + `favourite-courses?addToFav=${this.id}`
      window.open(url, '_blank');
    }
  }

  applyNow(){

  }

  viewUniversity(id){
    this.router.navigateByUrl(`about-university/${id}`)
  }

  onCourseClick(id){
    this.courseDetails = {}
    // this.router.navigateByUrl(`about-courses/${id}`)
  }
}
