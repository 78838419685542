import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ServicesService } from 'src/app/services.service';
import { Router } from '@angular/router';
declare var $:any;

@Component({
    selector: 'app-suggested-countries',
    templateUrl: './suggested-countries.component.html',
    styleUrls: ['./suggested-countries.component.css']
})
export class SuggestedCountriesComponent implements OnInit {
    popularCountryList: any=[];

    constructor(private service:ServicesService, private router:Router) { 

    }

    ngOnInit() {
        this.getPopularCountry()
    }

    getPopularCountry() {
        let url = environment.university.getPopularCountry + `?isFavourate=true&page=0&pageSize=999`
        this.service.getApi(url,2).subscribe((res:any)=>{
          if(res.status == 200) {
            this.popularCountryList = res.data.namesearch.content;
            setTimeout(() => {
              this.callSlickSlider();
            }, 500);
          }
        })
    }

    navigateTo(obj) {
        localStorage.setItem('countryObj',JSON.stringify(obj))
        this.router.navigate(['/top-universities',{country:obj.name.replace(/\s/g, '')}])
    }

    callSlickSlider() {
        $('.slider').slick({
          dots: false,
          infinite: false,
          speed: 300,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 500,
          responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      }

}
