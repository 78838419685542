import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-canada-search-course',
  templateUrl: './canada-search-course.component.html',
  styleUrls: ['./canada-search-course.component.css']
})
export class CanadaSearchCourseComponent implements OnInit {

  subject :any
  qualification :any = ""
  universityList :any
  courseUniversityList :any= [];
  popularUniversity:boolean = true

  constructor(private service : ServicesService,private router :Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getUniversityList()
    window.scrollTo(0, 0);
  }


  getUniversityList() {
    this.service.getApi(`university/v1.1/web/get-most-popular-university-by-country-using-subscription-type?country=Canada&page=0&pageSize=10`,2).subscribe((res :any ) => {
      console.log("res-->",res)
      this.universityList = []
      if(res.status == 200){
        this.universityList = res.data.RESULT_LIST.content ;
      }
    })
  }


  search(){
    this.service.showSpinner()
    this.popularUniversity = false
    let url = `course/v1.1/web/search-and-filter-course-details?page=0&country=Canada`
    if(this.subject){
      url = url + `&courseName=${this.subject}`
    }
    if(this.qualification){
      url = url + `&qualification=${this.qualification}`
    }
    this.service.getApi(url,2).subscribe((res:any) => {
      this.courseUniversityList = []
      this.service.hideSpinner()
      if(res.status == 200){
        this.courseUniversityList = res.data.list
      }
    })
  }

  readMore(id){
    if(id){
      this.router.navigateByUrl("about-university/"+ id)
    }
  }  

  openLink(social) {
    if (social == `Columbia`)
      window.open(`https://www.ubc.ca/`)
    else if (social == `Toronto`)
      window.open(`https://www.utoronto.ca/`)
    else if (social == `McGill`)
      window.open(`https://www.mcgill.ca/`)
  }

}
