import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { ActivatedRoute, Router } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent implements OnInit {

  newsdata : any;
  newsId : any;
  suggestedNewsList : any = [];

  constructor(private service:ServicesService,private activateRoute:ActivatedRoute,private router:Router) { 
    this.activateRoute.params.subscribe((param:any) => {
      console.log("params-->",param.id)
      this.newsId = param.id
    })
  }

  ngOnInit() {
    $('.slider').slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 500,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }

      ]
    });
    window.scrollTo(0, 0);
    this.getNewsDetail()
  }

  getNewsDetail(){
    this.service.showSpinner()
    this.service.getApi(`static/get-new-detail?newsId=${this.newsId}`,2).subscribe((res:any) => {
      console.log("country news -->",res)
      if(res.status == 200){
        this.service.hideSpinner()
        this.newsdata = res.data
        //  'https://res.cloudinary.com/dmabxaha1/image/upload/v1595839081/vhankxjuqt6ggxuiaxzb.jpg'
        // 'https://res.cloudinary.com/dmabxaha1/image/upload/v1595839157/zx3q1oi9ni0zmg2rdfxu.jpg'
        this.newsdata.imageUrl = res.data.imageUrl ? res.data.imageUrl : 'https://res.cloudinary.com/dmabxaha1/image/upload/v1595839382/ddkuelrup0wnsnbbjbu9.jpg'
        this.getSuggetionNews(this.newsdata.newsType)
      }
      this.service.hideSpinner()
    },(error : any) => {
      this.service.hideSpinner()      
    })
  }

  getSuggetionNews(type) {
      this.service.showSpinner()
      this.service.getApi(`static/get-new-list-by-news-type?newsType=${type}&page=0&pageSize=10`,2).subscribe((res:any) => {
        console.log("country news -->",res)
        if(res.status == 200){
          this.service.hideSpinner()
          this.suggestedNewsList = res.data
        }
        this.service.hideSpinner()
      },(error : any) => {
        this.service.hideSpinner()      
      })
  }

  viewNews(item) {
    this.service.showSpinner()
    this.router.navigateByUrl(`news-detail/${item.newsId}`)
  }

}
