import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';

@Component({
  selector: 'app-services-offering',
  templateUrl: './services-offering.component.html',
  styleUrls: ['./services-offering.component.css']
})
export class ServicesOfferingComponent implements OnInit {
  serviceOfferingData:any

  constructor(private service:ServicesService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.service.showSpinner()
    this.getData()
  }

  getData() {
    this.service.getApi('static/get-static-page-data?pageKey=Service Offering',2).subscribe((res :any) => {
      this.service.hideSpinner()
      if(res.status == 200) {
        this.serviceOfferingData = res.data
      }
    },(error:any) => {
      this.service.hideSpinner()
    })
  }

}
