import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-compare-courses-read-more',
  templateUrl: './compare-courses-read-more.component.html',
  styleUrls: ['./compare-courses-read-more.component.css']
})
export class CompareCoursesReadMoreComponent implements OnInit {
  coursedetails: any;
  

  constructor(private service:ServicesService,private activateRoute:ActivatedRoute) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.activateRoute.params.subscribe((param:any) => {
      if(param){
        this.getCourseDetails(param.id)
      }
    })
  }


  getCourseDetails(id){
    this.service.getApi(`course/v1.1/web/view-specific-course?id=${id}`,2).subscribe((res : any) => {
      if(res.status == 200){
        this.coursedetails = (res.data)
        console.log("course list -->>",this.coursedetails)
      }
    })
  }

}
