import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-uk-search-course',
  templateUrl: './uk-search-course.component.html',
  styleUrls: ['./uk-search-course.component.css']
})
export class UkSearchCourseComponent implements OnInit {

  subject :any ;
  qualification :any = "";
  universityList :any ;
  courseUniversityList :any = [];
  popularUniversity :boolean = true;

  constructor(private service : ServicesService,private router:Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getUniversityList()
  }

  getUniversityList() {
    this.service.getApi(`university/v1.1/web/get-most-popular-university-by-country-using-subscription-type?country=United%20Kingdom&page=0&pageSize=10`,2).subscribe((res :any ) => {
        console.log("res-->",res)
        this.universityList = []
        if(res.status == 200){
            this.universityList = res.data.RESULT_LIST.content ;
        }
    })
  }

  search(){
    this.service.showSpinner()
    this.popularUniversity = false
    let url = `course/v1.1/web/search-and-filter-course-details?page=0&country=United%20Kingdom`
    if(this.subject){
      url = url + `&courseName=${this.subject}`
    }
    if(this.qualification){
      url = url + `&qualification=${this.qualification}`
    }
    this.service.getApi(url,2).subscribe((res:any) => {
      this.courseUniversityList = []
      console.log("res -->>",res)
      this.service.hideSpinner()
      if(res.status == 200){
        this.courseUniversityList = res.data.list
      }
    })
  }

  readMore(id){
    if(id){
      this.router.navigateByUrl("about-university/"+ id)
    }
  }  

  openLink(social) {
    if (social == `SchoolOfEconomics`)
      window.open(`http://www.lse.ac.uk/`)
    else if (social == `Oxford`)
      window.open(`https://www.ox.ac.uk/`)
    else if (social == `CollegeLondon`)
      window.open(`https://www.ucl.ac.uk/`)
    else if (social == `Cambridge`)
      window.open(`https://www.cam.ac.uk/`)
  }
}
