import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { AboutUsComponent } from './component/about-us/about-us.component';
import { TermsConditionsComponent } from './component/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './component/privacy-policy/privacy-policy.component';
import { FaqComponent } from './component/faq/faq.component';
import { HowToApplyComponent } from './component/how-to-apply/how-to-apply.component';
import { ServicesOfferingComponent } from './component/services-offering/services-offering.component';
import { HowItWorksComponent } from './component/how-it-works/how-it-works.component';
import { UsaSearchCourseComponent } from './component/search-course/usa-search-course/usa-search-course.component';
import { CanadaSearchCourseComponent } from './component/search-course/canada-search-course/canada-search-course.component';
import { NewzealandSearchCourseComponent } from './component/search-course/newzealand-search-course/newzealand-search-course.component';
import { IrelandSearchCourseComponent } from './component/search-course/ireland-search-course/ireland-search-course.component';
import { UkSearchCourseComponent } from './component/search-course/uk-search-course/uk-search-course.component';
import { AustraliaSearchCourseComponent } from './component/search-course/australia-search-course/australia-search-course.component';
import { ServicesService } from './services.service';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { LoginComponent } from './component/login/login.component';
import { ForgetPasswordComponent } from './component/forget-password/forget-password.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { AboutUniversityComponent } from './component/about-university/about-university.component';
import { UniversityListComponent } from './component/university-list/university-list.component';
import { AboutCoursesComponent } from './component/about-courses/about-courses.component';
import { NewsComponent } from './component/news/news.component';
import { NewsDetailComponent } from './component/news-detail/news-detail.component';
import { SeachCoursesComponent } from './component/seach-courses/seach-courses.component';
import { CompareUniversityComponent } from './component/compare-university/compare-university.component';
import { CompareCoursesComponent } from './component/compare-courses/compare-courses.component';
import { CompareCoursesReadMoreComponent } from './component/compare-courses-read-more/compare-courses-read-more.component';
import { CompareUniversityReadMoreComponent } from './component/compare-university-read-more/compare-university-read-more.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AgmCoreModule } from '@agm/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { RatingModule} from "ngx-rating";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { TopUniversitiesComponent } from './component/top-universities/top-universities.component';
import { SuggestedCoursesComponent } from './component/suggested-courses/suggested-courses.component';
import { SuggestedCountriesComponent } from './component/suggested-countries/suggested-countries.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    AboutUsComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    FaqComponent,
    HowToApplyComponent,
    ServicesOfferingComponent,
    HowItWorksComponent,
    UsaSearchCourseComponent,
    CanadaSearchCourseComponent,
    NewzealandSearchCourseComponent,
    IrelandSearchCourseComponent,
    UkSearchCourseComponent,
    AustraliaSearchCourseComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ContactUsComponent,
    AboutUniversityComponent,
    UniversityListComponent,
    AboutCoursesComponent,
    NewsComponent,
    NewsDetailComponent,
    SeachCoursesComponent,
    CompareUniversityComponent,
    CompareCoursesComponent,
    CompareCoursesReadMoreComponent,
    CompareUniversityReadMoreComponent,
    TopUniversitiesComponent,
    SuggestedCoursesComponent,
    SuggestedCountriesComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    NgSelectModule,
    MatAutocompleteModule,
    RatingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC-v9QfZ9vdJtGL9K3K3r5eDLKUwuQtTOM',
      libraries: ["places", "geometry"]
    }),
  ],
  providers: [ServicesService],
  bootstrap: [AppComponent]
})
export class AppModule { }
