import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  newsList:any = []
  newsType:any;
  searchKey:any;
  constructor(private service:ServicesService,private router:Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.newsType = "UNIVERSITY";
    this.getCountryNews()
  }

  getCountryNews(){
      this.service.showSpinner()
      let url = `static/get-new-list-by-news-type?newsType=${this.newsType}&page=0&pageSize=100`
      if(this.searchKey){
        url = url + `&search=${this.searchKey}`;
      }
      this.service.getApi(url,2).subscribe((res:any) => {
          this.newsList = []
          if(res.status == 200){
              this.newsList = res.data;
          }
          this.service.hideSpinner()
      },(error : any) => {
          this.service.hideSpinner()
      })
  }

  newsDetail(id){
    this.router.navigateByUrl("news-detail/"+id)
  }

}
