import { Component, OnInit, Input } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';

@Component({
    selector: 'app-suggested-courses',
    templateUrl: './suggested-courses.component.html',
    styleUrls: ['./suggested-courses.component.css']
})
export class SuggestedCoursesComponent implements OnInit {
    courseList: any=[];
    @Input() title:any="More Popular Courses";
    constructor(private service:ServicesService , private router:Router) { }

    ngOnInit() {
        this.getPopularCourses();
        // setInterval(() => {
        //     console.log(this.title)
        // }, 3000);
    }

    getPopularCourses() {
        let url =  environment.course.getPopularCourse + `?page=0&pagesize=9999`;
        this.service.showSpinner();
        this.service.getApi(url,2).subscribe((res:any)=>{
          this.service.hideSpinner();
          if(res.status == 200) {
            this.courseList = res.data.FavourateCourses.content
          }
        })
    }

    navigateTo(item) {
        this.router.navigate(['about-courses',item.courseId])
    }

}
