import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  faqList :any = []

  constructor(private service: ServicesService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.service.showSpinner();
    this.getData();
  }

  getData() {
    this.service.getApi('static/get-faq-data?page=0&pageSize=10',2).subscribe((res:any) => {
      if(res.status == 200) {
        this.faqList = res.data.content
      }
      this.service.hideSpinner()
    },(error:any) => {
      this.service.hideSpinner()
    })
  }

}
