import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { AboutUsComponent } from './component/about-us/about-us.component';
import { TermsConditionsComponent } from './component/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './component/privacy-policy/privacy-policy.component';
import { HowToApplyComponent } from './component/how-to-apply/how-to-apply.component';
import { FaqComponent } from './component/faq/faq.component';
import { ServicesOfferingComponent } from './component/services-offering/services-offering.component';
import { HowItWorksComponent } from './component/how-it-works/how-it-works.component';
import { UsaSearchCourseComponent } from './component/search-course/usa-search-course/usa-search-course.component';
import { CanadaSearchCourseComponent } from './component/search-course/canada-search-course/canada-search-course.component';
import { NewzealandSearchCourseComponent } from './component/search-course/newzealand-search-course/newzealand-search-course.component';
import { IrelandSearchCourseComponent } from './component/search-course/ireland-search-course/ireland-search-course.component';
import { AustraliaSearchCourseComponent } from './component/search-course/australia-search-course/australia-search-course.component';
import { UkSearchCourseComponent } from './component/search-course/uk-search-course/uk-search-course.component';
import { LoginComponent } from './component/login/login.component';
import { ForgetPasswordComponent } from './component/forget-password/forget-password.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { AboutUniversityComponent } from './component/about-university/about-university.component';
import { UniversityListComponent } from './component/university-list/university-list.component';
import { AboutCoursesComponent } from './component/about-courses/about-courses.component';
import { NewsComponent } from './component/news/news.component';
import { NewsDetailComponent } from './component/news-detail/news-detail.component';
import { SeachCoursesComponent } from './component/seach-courses/seach-courses.component';
import { CompareUniversityComponent } from './component/compare-university/compare-university.component';
import { CompareCoursesComponent } from './component/compare-courses/compare-courses.component';
import { CompareUniversityReadMoreComponent } from './component/compare-university-read-more/compare-university-read-more.component';
import { CompareCoursesReadMoreComponent } from './component/compare-courses-read-more/compare-courses-read-more.component';
import { TopUniversitiesComponent } from './component/top-universities/top-universities.component';

const routes: Routes = [
    // { path:'', redirectTo: "/dashboard", pathMatch :"full" },
    { path: '',component: DashboardComponent },
    { path: 'about-us', component: AboutUsComponent },
    { path: 'top-universities', component: TopUniversitiesComponent },
    { path: 'terms-conditions', component: TermsConditionsComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'how-to-apply', component: HowToApplyComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'services-offering', component: ServicesOfferingComponent },
    { path: 'how-it-works', component: HowItWorksComponent },
    { path: 'usa-search-course', component: UsaSearchCourseComponent },
    { path: 'canada-search-course', component: CanadaSearchCourseComponent },
    { path: 'newzealand-search-course', component: NewzealandSearchCourseComponent },
    { path: 'ireland-search-course', component: IrelandSearchCourseComponent },
    { path: 'australia-search-course', component: AustraliaSearchCourseComponent },
    { path: 'uk-search-course', component: UkSearchCourseComponent },
    { path: 'login', component: LoginComponent },
    { path: 'forget-password', component: ForgetPasswordComponent },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'about-university/:id', component: AboutUniversityComponent },
    { path: 'about-courses/:id', component: AboutCoursesComponent },
    { path: 'university-list', component: UniversityListComponent },
    { path: 'news', component: NewsComponent },
    { path: 'news-detail/:id', component: NewsDetailComponent},
    { path: 'search-courses', component: SeachCoursesComponent },
    { path: 'compare-university', component: CompareUniversityComponent },
    { path: 'compare-university-read-more/:id', component: CompareUniversityReadMoreComponent },
    { path: 'compare-courses', component: CompareCoursesComponent },
    { path: 'compare-courses-read-more/:id', component: CompareCoursesReadMoreComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule { }
