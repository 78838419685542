import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  OpenTab(tab){
    let url = window.location.href
    if(tab == 'rep'){
      if(url.includes('localhost') || url.includes('webstag')) {
        window.open('https://repstag.univ.global', "_blank");
      }else {
        window.open('https://rep.univ.global', "_blank");
        // window.open('https://repstag.univ.global', "_blank");
      }
    }else if(tab == 'stu'){
      if(url.includes('localhost') || url.includes('webstag')) {
        window.open('https://stustag.univ.global', "_blank");
      }else {
        window.open('https://student.univ.global', "_blank");
        // window.open('https://stustag.univ.global', "_blank");
      }
    }else if(tab == 'univ'){
      if(url.includes('localhost') || url.includes('webstag')) {
        window.open('https://insstag.univ.global', "_blank");
      }else {
        window.open('https://ins.univ.global', "_blank");
        // window.open('https://insstag.univ.global', "_blank");
      }
    }
   
  }

}
