import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css']
})
export class HowItWorksComponent implements OnInit {
  howItWorksData: any;

  constructor(private service: ServicesService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.service.showSpinner()
    this.getData()
  }

  getData() {
    this.service.getApi('static/get-static-page-data?pageKey=How It Works',2).subscribe((res :any) => {
      this.service.hideSpinner()
      if(res.status == 200) {
        this.howItWorksData = res.data
      }
    },(error:any) => {
      this.service.hideSpinner()
    })
  }

}
