export const environment = {
  version: '1.0.4',
  production: true,
  university: {
    getPopularCountry: 'static/get-all-byId-byCountry-search-data',
    getPopularUniversity: 'university/v1.1/get-university-by-country-for-most-popular-university',
    getFavourateUniversity: 'university/v1.1/get-favourate-university-list',
    getPriorityBased: 'university/v1.1/get-university-list-priority-based',
    getUniversityList: 'university/v1.1/get-all-university-list',
    getMatchingUniversity: 'university/v1.1/web/get-matching-university',
    
  },
  course: {
    getSeoAndCourseGroup: 'course/v1.1/web/get-seo-and-courseG-group-list',
    searchCourse: 'course/v1.1/web/search-and-filter-course-details',
    getPopularCourse: 'course/v1.1/web/get-all-favourate-courses'
  },
  static: {
    getCountryList: 'static/get-all-byId-byCountry-search-data'
  }
};
