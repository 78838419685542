import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { Router } from '@angular/router';
declare var $:any
@Component({
  selector: 'app-compare-courses',
  templateUrl: './compare-courses.component.html',
  styleUrls: ['./compare-courses.component.css']
})
export class CompareCoursesComponent implements OnInit {
  courseList: any = [];
  compareCourseList: any = [];
  infoObj:any = {
    subjectRanking: 'Subject rankings are provided by one the of reputable ranking resources. Please check the university website for further details',
    courseType: 'Course type names may vary based on individual universities. Please check the university website for further details',
    courseDuration: ' Course duration may vary based on individual universities. Please check the university website for further details',
    startDate: 'Course start dates may vary based on individual universities. Please check the university website for further details',
    modeOfStudy: 'Additional modes of study such as part time and distance learning may also be available for this course. Please check the university website for further details',
    tutionFees: 'Fees are for indicative purposes, please check the University website for details',
    scholarship: 'Scholarships are available. Please check the University website for further details',
    assessment: 'Assessment types may vary based on individual universities. Please check the university website for further details',
    workExperience: 'Work Experience requirements may vary based on individual universities. Please check the university website for further details',
    entryRequirements: 'Entry requirements may vary based on individual universities. Please check the university website for further details',
    careers: 'Detailed career destinations may be available on the university website. Please check the university website for further details'
  }
  constructor(private service:ServicesService,private router:Router) { }

  ngOnInit() {
  window.scrollBy(0,0);
    this.getCompareList()
  }


  getCompareList(){
      // // let primaryKey = localStorage.getItem('compareCourseId')
      // this.service.getApi(`course/get-course-compare-list?primaryKey=${primaryKey}`,2).subscribe((res:any) => {
      //   this.courseList = []
      //   this.compareCourseList = []
      //     console.log("res -->",res)
      //     if(res.status == 200){
      //         this.courseList = res.data
      //         // this.courseList = [
      //         //   {courseId : 4},
      //         //   {courseId : 5},
      //         //   {courseId : 6}
      //         // ]
              
      //     }
      // })
      this.courseList = JSON.parse(localStorage.getItem('compareCourseId'))
      this.courseList.forEach(element => {
        this.getCourseDetails(element)
        this.service.hideSpinner()                 
    });
  }

  getCourseDetails(id){
    this.service.getApi(`course/v1.1/web/view-specific-course?id=${id}`,2).subscribe((res : any) => {
      if(res.status == 200){
        this.compareCourseList.push(res.data.course)
        console.log("course list -->>",this.compareCourseList)
      }
    })
  }

  removeFromCompare(id){
    let arr = JSON.parse(localStorage.getItem('compareCourseId'));
    let index = arr.findIndex(x=>x == id) ;

    if(index != -1) {
      arr.splice(index,1);
      let index2 = this.compareCourseList.findIndex(x=>x.courseId == id)
      this.compareCourseList.splice(index2,1)
      localStorage.setItem('compareCourseId',JSON.stringify(arr))
    }
    if(arr.length == 0) {
      this.router.navigateByUrl('search-courses')
    }
    // this.service.postApi(`course/remove-course-from-compare?primaryKey=${primaryKey}&courseId=${id}`,data,2).subscribe((res:any) => {
    //   console.log("res-->",res)
    //   this.getCompareList()
    // })
  }

  read_more(id){
      this.router.navigateByUrl(`about-courses/${id}`);
  }

  applyNow(id) {
    this.service.showErrorMessage('You need to login first!')
  }
}
