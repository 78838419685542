import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService } from 'src/app/services.service';
import * as uuid from 'uuid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
declare var $ : any

@Component({
  selector: 'app-about-university',
  templateUrl: './about-university.component.html',
  styleUrls: ['./about-university.component.css']
})
export class AboutUniversityComponent implements OnInit {
  id: any;
  // lat : any =51.7548
  // long : any=-1.2544
  universitydetails: any;
  responseMessage:any 
  courseList: any = [];
  infoObj :any=
  {
    subjectRanking: 'Subject rankings are provided by one the of reputable ranking resources. Please check the university website for further details',
    courseType: 'Course type names may vary based on individual universities. Please check the university website for further details',
    courseDuration: ' Course duration may vary based on individual universities. Please check the university website for further details',
    startDate: 'Course start dates may vary based on individual universities. Please check the university website for further details',
    modeOfStudy: 'Additional modes of study such as part time and distance learning may also be available for this course. Please check the university website for further details',
    tutionFees: 'Fees are for indicative purposes, please check the University website for details',
    scholarship: 'Scholarships are available. Please check the University website for further details',
    assessment: 'Assessment types may vary based on individual universities. Please check the university website for further details',
    workExperience: 'Work Experience requirements may vary based on individual universities. Please check the university website for further details',
    entryRequirements: 'Entry requirements may vary based on individual universities. Please check the university website for further details',
    careers: 'Detailed career destinations may be available on the university website. Please check the university website for further details',
    worldRanking: 'World rankings are provided by one of the reputable ranking resources. Please check the university website for further details',
    countryRanking: 'Country rankings are provided by one the of reputable ranking resources. Please check the university website for further details',
    livingExpenses: 'Living Expenses are for indicative purposes, C refers to Campus accommodation & OC refers to Off Campus Accommodation. Please check the university website for further details',
    accommodation: ' Subject to availability, provided a booking is made before the deadline. Please check the university website for further details',
    researchFacility: 'Research facilities may be available centrally or within a departments. Please check the university website for further details',
    researchRanking: 'Research rankings are provided by one of the reputable ranking resources. Please check the university website for further details',
    employabilityRanking: 'Employability rankings are provided by one of the reputable ranking resources. Please check the university website for further details',
    partTimeJobs: 'Part-time jobs on campus are subject to availability. Please check the university website for further details'
  }
  popularUniversityList: any=[];
  popularCourses: any=[];

  constructor(private activateRoute:ActivatedRoute,private service:ServicesService,private router:Router) { }

  ngOnInit() {
   
    this.activateRoute.params.subscribe((res:any) => {
      this.id =  res.id
      this.getFavourateCourses()
      this.getUniversitydetails();
    this.getUniversityPopularUniversity();
    })   
    
    window.scrollTo(0, 0);     
  }

  getUniversityPopularUniversity() {
    this.popularUniversityList = []
    let url = environment.university.getFavourateUniversity;
    this.service.getApi(url,2).subscribe((res:any)=>{
        if(res.status == 200) {
          this.popularUniversityList = res.data.data
        }
    })

  }

  getUniversitydetails() {
    this.service.showSpinner()
    this.service.getApi(`university/v1.1/web/get-university-particular-data?id=${this.id}`,2).subscribe((res :any ) => {
      console.log("res-->",res)
      if(res.status == 200){
        this.universitydetails = res.data.data;
        this.universitydetails.lat = Number(this.universitydetails.lat)
        this.universitydetails.lng = Number(this.universitydetails.lng)
        this.universitydetails.imageUrl = this.universitydetails.imageUrl ? this.universitydetails.imageUrl : 'assets/images/univ_bg.jpg'
      }else{
        this.universitydetails = []
      }
      this.service.hideSpinner()
    })
  }

  addToCompare(id){
    // this.service.showSpinner()
    // let primaryId ;
    // if(localStorage.getItem('compareId')){
    //   primaryId = localStorage.getItem('compareId')
    // }else{
    //   primaryId =  uuid.v4();
    // }
    // let data = {}
    // localStorage.setItem('compareId',primaryId)
    //   this.service.postApi(`university/add-university-to-compare?primaryKey=${primaryId}&universityId=${id}`,data,2).subscribe((res:any) => {
    //       console.log("res-->",res)
    //       if(res.status == 200){
    //         this.router.navigateByUrl('compare-university')
    //       }else{
    //         this.service.hideSpinner()
    //         this.responseMessage = res.message
    //         $('#responseModal').modal('show')
    //       }
    //   })
    let arr = []
      if(localStorage.getItem('compareUniversityId')){
        arr = JSON.parse(localStorage.getItem('compareUniversityId'));
        if(arr.length >=3) {
          alert('You have already added 3 universities in comparison list. Please remove to add more!')
          this.router.navigateByUrl('compare-university')
          return;
        }
        let index = arr.findIndex(x=>x == id);
        if(index == -1) {
          arr.push(id);
          localStorage.setItem('compareUniversityId',JSON.stringify(arr))
          this.router.navigateByUrl('compare-university')
        }else {
          this.responseMessage = 'University already added for comparison!'
          $('#responseModal').modal('show')
        }
      }else { 
        arr.push(id);
        localStorage.setItem('compareUniversityId',JSON.stringify(arr))
        this.router.navigateByUrl('compare-university')
      }
  }


  viewCourses(id){
    this.router.navigateByUrl(`about-courses/${id}`)
  }


  viewUniversity(data) {
    this.router.navigate(['about-university',data.universityDetailsId])
    this.universitydetails = {}
  }

  getFavourateCourses() {
    let url = environment.course.getPopularCourse+`?page=0&pagesize=999&universityId=${this.id}`
    console.log(url)
    this.service.getApi(url,2).subscribe((res:any)=>{
      if(res.status == 200) {
        this.popularCourses = res.data.FavourateCourses.content
        console.log('called',this.popularCourses)
      }
    })
  }
}
